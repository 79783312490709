<template>
  <div class="main-wrapper" :class="[mainWrapperClass]">
    <!---Navigation-->
    <Navbar
      :topbarColor="topbarColor"
      :logo="require('@/assets/images/logo/logo-mini.png')"
      :title="logotitle"
      :logout="logout"
      :canAccess="canAccess"
    />
    <SideBar
      parent=".main-wrapper"
      :sidebarLinks="sidebarLinks"
      :canAccess="canAccess"
      :logout="logout"
      @updatedSidebar="redirectToSection"
    />
    <!---Page Container-->
    <div class="main-container-fluid">
      <!--      <Breadcrumb :context="sidebarLinks"/>-->

      <vs-row v-if="messages">
        <vs-col vs-w="12" v-for="(oneMessage, index) in messages" :key="index">
          <vs-alert
            v-if="oneMessage"
            :title="oneMessage.title"
            :active.sync="oneMessage.display"
            :color="oneMessage.type.toLowerCase()"
            closable
            close-icon="close"
          >
            <div v-html="oneMessage.text"></div>
          </vs-alert>
        </vs-col>
      </vs-row>

      <transition
        name="router-anim"
        enter-active-class="fade-enter-active fade-enter"
        leave-active-class="fade-leave-active fade-enter"
      >
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import Navbar from "@/layout/full/header/Navbar.vue";
import SideBar from "@/layout/full/sidebar/SideBar.vue";
// import Breadcrumb from '@/layout/full/breadcrumb/Breadcrumb.vue';
import themeConfig from "@/../themeConfig.js";

export default {
  name: "MainContainer",
  components: {
    Navbar,
    SideBar,
    // Breadcrumb
  },
  data: () => ({
    topbarColor: themeConfig.topbarColor,
    logotitle: themeConfig.logotitle,
    username: "",
    role: "",
    sidebarLinks: [],
  }),

  created() {
    this.getUserInfo();
    this.getMenu();
    //attach listeners to http events
    this.$http.interceptors.response.use(
      (response) => {
        if (response && response.data && response.data.result) {
          return response.data;
        } else {
          return response;
        }
      },
      (error) => {
        if (error.response) {
          if (error.response.data) {
            if (
              error.response.data.result === false &&
              error.response.data.name === "gateway"
            ) {
              return Promise.reject(error.response.data);
            } else {
              let errorMsg = error.response.data.message + "\nReason: ";
              for (let i in error.response.data.errors) {
                errorMsg += `${i} -> ${error.response.data.errors[i]}`;
              }
              let myError = new Error(errorMsg);
              myError.code = error.response.status;
              return Promise.reject(myError);
            }
          } else {
            return Promise.reject(error);
          }
        } else return Promise.reject(error);
      }
    );
  },

  updated() {
    //call the backend and get the user information
    this.getUserInfo();
    this.getMenu();
    this.$nextTick(() => {
      var item = document.querySelector(".router-link-active");
      if (item !== null) {
        item.parentNode.classList.add("vs-sidebar-item-active");
        item.parentNode.parentNode.parentNode.parentNode.classList.add(
          "vs-sidebar-group-open"
        );
        item.parentNode.parentNode.parentNode.style.maxHeight = "none";
      }
    });
  },

  methods: {
    redirectToSection: function (sidebar) {
      if (this.$router.currentRoute.name === "dashboard") {
        let enableDashboardAccess = false;
        sidebar.forEach((mainMenu) => {
          if (mainMenu.name === "Account Overview") {
            enableDashboardAccess = true;
          }
        });

        if (!enableDashboardAccess) {
          let newRouteRedirect = null;
          //get the first link and redirect to it.
          for (let i = 0; i < sidebar.length; i++) {
            if (sidebar[i].url !== "#") {
              newRouteRedirect = sidebar[i];
              break;
            } else if (sidebar[i].url === "#" && sidebar[i].child) {
              newRouteRedirect = sidebar[i].child[0];
            }
          }

          if (newRouteRedirect) {
            setTimeout(() => {
              this.$store.dispatch("globalMessages/clear");
              this.$router.push({ name: newRouteRedirect.name });
            }, 201);
          }
        } else {
          let newRouteRedirect = sidebar[0];
          this.$router.push({ name: newRouteRedirect.name });
        }
      }
    },

    canAccess: function (context) {
      return this.$acl.hasAccess(context);
    },

    updateTopbarColor(val) {
      this.topbarColor = val;
    },

    getUserInfo() {
      const _self = this;
      this.$vs.loading.close("#global-loading-container>.con-vs-loading");
      this.$store.dispatch("clearLoaderSPinner");
      this.username = this.$store.getters["auth/getUser"];
      this.role = this.$store.getters["auth/getRoles"];

      if (!this.username || !this.role) {
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
          this.$http({
            url: "/user",
            method: "GET",
            headers: headers,
          })
            .then((response) => {
              this.$store.dispatch("auth/setUser", response);
            })
            .catch((error) => {
              handleErrorResponse(error);
            });
        }
      }

      function handleErrorResponse(error) {
        _self.$store.dispatch("auth/clear");
        if (_self.$router.currentRoute.name !== "Login") {
          _self.$router.push({ name: "Login" });

          setTimeout(() => {
            for (let code in error) {
              let oneError = error[code];
              _self.pushMessage({
                type: "danger",
                title: `Error: ${oneError.code}`,
                text: oneError.message.toString(),
              });
            }
          }, 500);
        }
      }
    },

    getMenu(OldError = false) {
      const menu = this.$store.getters["menuModule/getMenu"];
      const latUpdate = this.$store.getters["menuModule/getLastUpdated"];
      this.$vs.loading.close("#global-loading-container>.con-vs-loading");

      if (!menu || !latUpdate) {
        const getMenuURl = this.$apiList.getMenuURl;
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
        }

        this.$http({
          url: getMenuURl,
          method: "GET",
          headers: headers,
        })
          .then((response) => {
            this.$store.dispatch("menuModule/setMenuRecords", response.data);

            this.sidebarLinks = response.data;
            this.redirectoFirstMenuEntry();
            this.$forceUpdate();
          })
          .catch((error) => {
            if (OldError === false) {
              this.$acl.refreshToken();
              this.getMenu(error);
            } else {
              this.logout(true);
            }
          });
      } else {
        this.sidebarLinks = menu;
        this.redirectoFirstMenuEntry();
      }
    },

    redirectoFirstMenuEntry() {
      if (this.sidebarLinks && this.$router.currentRoute.name === "dashboard") {
        let firstEntry = this.sidebarLinks[0];
        if (firstEntry.link && firstEntry.link.trim() !== "") {
          this.$router.push({ path: firstEntry.link });
        } else if (
          firstEntry.childs &&
          firstEntry.childs[0] &&
          firstEntry.childs[0].link &&
          firstEntry.childs[0].link.trim() !== ""
        ) {
          this.$router.push({ path: firstEntry.childs[0].link });
        }
      }
    },

    logout(force = false) {
      this.$swal({
        title: "Logout",
        text: "Are you sure you want to logout ?",
        type: "warning",
        confirmButtonText: "yes",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonColor: "#008080",
        customClass: {
          title: "error",
          confirmButton: "confirm-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          this.logoutFromBackoffice(force);
        }
      });
    },

    logoutFromBackoffice(force = false) {
      if (force) {
        const token = this.$store.getters["auth/getToken"];
        let headers = {};
        if (token) {
          headers["X-AUTH-TOKEN"] = token;
        }
        const logoutUrl = this.$apiList["logout"];
        this.$http({
          url: logoutUrl,
          method: "POST",
          headers: headers,
        }).finally(() => {
          this.$store.dispatch("globalMessages/push", {
            type: "success",
            title: "",
            text: "You have Logged out of Spread Platform. See you back soon.",
          });

          this.$store.dispatch("auth/clear");
          if (this.$router.currentRoute.name !== "Login") {
            this.$router.push({ name: "Login" });
          }
        });
      }
    },
  },
  computed: {
    messages: function () {
      return this.$store.getters["globalMessages/list"];
    },

    sidebarWidth: function () {
      return this.$store.state.sidebarWidth;
    },

    mainWrapperClass: function () {
      if (this.sidebarWidth === "default") {
        return "main-wrapper-default";
      } else if (this.sidebarWidth === "mini") {
        return "main-wrapper-mini";
      } else if (this.sidebarWidth) {
        return "main-wrapper-full";
      }
      return "default";
    },
  },
};
</script>

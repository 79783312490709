<template>
  <header class="gridx">
    <vs-navbar
      v-model="indexActive"
      :color="topbarColor"
      class="topnavbar"
      text-color="rgba(255,255,255,0.7)"
      active-text-color="rgba(255,255,255,1)"
    >
      <!---
      Template logo
      -->
      <div slot="title" class="themelogo">
        <a href="/">
          <img :src="logo" v-if="logo" style="height: 40px" alt="Dashboard" />
          <span class="logo-text fw-light" v-if="title">Jamalouki</span>
        </a>
      </div>
      <!---
      Mobile toggle
      -->
      <div slot="title">
        <div class="hiddenDesktop cursor-pointer" @click.stop="activeSidebar">
          <vs-icon icon="menu"></vs-icon>
        </div>
      </div>
      <!---
      Desktop Toggle
      -->
      <div
        class="hiddenMobile cursor-pointer"
        @click="
          forceUser = false;
          reduceSidebar();
        "
        v-show="!showToggle"
      >
        <vs-icon icon="menu"></vs-icon>
      </div>
      <div
        class="hiddenMobile cursor-pointer"
        @click="
          forceUser = true;
          fullSidebar(true);
        "
        v-show="showToggle"
      >
        <vs-icon icon="menu"></vs-icon>
      </div>

      <vs-spacer></vs-spacer>

      <!--<RecentNotification v-if="!hideLogs"-->
      <!--class="mr-20"-->
      <!--&gt;</RecentNotification>-->
      <vs-dropdown vs-trigger-click class="cursor-pointer">
        <vs-button
          class="special-button"
          size="small"
          color="#FFFFFF"
          :style="{ position: 'relative', top: '1px', right: '1%' }"
          type="filled"
        >
          <span class="hide-in-minisidebar">
            <i  v-if="!photo" class="mdi mdi-account" style="font-size: 1.2em !important"></i>
            <img  v-if="photo"  height="25px" :src="imageBaseUrl+photo">

            {{ userName }}
          </span>
        </vs-button>
        <vs-dropdown-menu class="user-dd common-dd topbar-dd">
          <div
            class="d-flex align-items-center p-3 background-menu text-black mb-2 profile-dropdown-container"
          >
            <div class="user-image">
              <i  v-if="!photo" class="mdi mdi-account" style="font-size: 48px !important"></i>
              <img  v-if="photo" style="width: 48px!important; height: 48px" :src="imageBaseUrl+photo">
            </div>
            <div class="ml-2">
              <h4 class="user-profile">{{ userName }}</h4>
              <a class="logout-button" :href="'/edit/core/profileuser?cassielId='+userName">Profile</a>
              <br>
              <button class="logout-button" @click="logout">Logout</button>
            </div>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>
    </vs-navbar>
  </header>
</template>
<style>
.logout-btn {
  background: white !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border: 1px solid black !important;
}

.logout-btn:hover {
  color: white !important;
}

.con-vs-dropdown--menu.user-dd {
}
.profile-dropdown-container {
  display: flex;
  align-items: center;
  border: 1px solid #c1c6e5;
  border-radius: 5px;
  padding: 14px 18px;
}
.profile-dropdown-container .user-image {
  margin-right: 14px;
}
.profile-dropdown-container .user-profile {
  font-size: 18px;
  font-weight: 400;
  text-transform: capitalize;
  color: #202228;
}
.profile-dropdown-container .logout-button {
  margin-top: 4px;
  color: #586bb2;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  border: 0;
  background: transparent;
}
.background-menu {
  /* background: linear-gradient(40deg, #2096ff, #05ffa3) !important; */
}
</style>
<script>
export default {
  name: "Navbar",
  props: {
    topbarColor: {
      type: String,
      default: "#2962ff",
    },
    title: {
      type: String,
    },
    logo: {
      type: String,
    },
    currentRouteName: {
      type: String,
    },
    canAccess: {
      type: Function,
    },
    logout: {
      type: Function,
    },
    role: {
      type: String,
    }
  },
  components: {},
  data: () => ({
    indexActive: 0,
    showToggle: false,
    windowWidth: window.innerWidth,
    forceUser: false,
    imageBaseUrl:null
  }),

  created() {
    this.imageBaseUrl = process.env.VUE_APP_FILES_BASE_URL;
  },

  methods: {
    //This is for sidebar trigger in mobile
    activeSidebar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", true);
    },
    //This is for sidebar trigger in Desktop
    reduceSidebar() {
      this.$store.commit("TOGGLE_REDUCE_SIDEBAR", true);
      this.$store.dispatch("updateSidebarWidth", "mini");
      this.showToggle = true;
    },
    fullSidebar() {
      this.$store.commit("TOGGLE_REDUCE_SIDEBAR", false);
      this.$store.dispatch("updateSidebarWidth", "default");
      this.showToggle = false;
    },
  },
  computed: {
    userName() {
      return this.$store.getters["auth/getUser"];
    },
    photo() {
      return this.$store.getters["auth/getPhoto"];
    },
  },
};
</script>

<template lang="html">
  <div id="parentx">
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />
    <vs-sidebar
      default-index="1"
      :hiddenBackground="doNotClose"
      color="primary"
      class="left-sidebar"
      spacer
      v-model="isSidebarActive"
      :click-not-close="doNotClose"
      :reduce="isSidebarReduced"
    >
      <!-- <div v-if="userName" class="header-sidebar text-center" slot="header">
        <i class="mdi mdi-account" style=" font-size: 48px !important;"></i>
        <h4>{{ userName }}</h4>
      </div> -->

      <div class="vs-scrollable">
        <div>
          <template v-for="(sidebarLink, index) in mainMenuLinks">
            <!-- Small Cap -->
            <span
              :key="index + '.' + index"
              v-if="
                haveViewAccess(sidebarLink.widget, sidebarLink.entity) &&
                sidebarLink.link
              "
              class="small-cap"
            >
              <i
                :class="'fa fa-' + sidebarLink.icon"
                :key="index + 'icon'"
                style="
                  ffloat: left;
                  font-size: 1rem !important;
                  padding: 17px 0 15px 15px;
                  color: #586bb2;
                  z-index: 2;
                  position: absolute;
                  top: 0;
                "
              ></i>
              <vs-sidebar-item
                :to="sidebarLink.link"
                :index="sidebarLink.index"
                v-on:click="activeItem"
                :class="'page-' + sidebarLink.widget + '-' + sidebarLink.entity"
              >
                <span class="hide-in-minisidebar">{{ sidebarLink.name }}</span>
              </vs-sidebar-item>
            </span>

            <template v-else-if="!sidebarLink.link">
              <!-- Single Menu -->
              <vs-sidebar-item
                :key="sidebarLink.index"
                v-on:click="activeItem"
                :index="sidebarLink.index"
                v-if="
                  haveViewAccess(sidebarLink.widget, sidebarLink.entity) &&
                  !sidebarLink.childs
                "
                :class="'page-' + sidebarLink.widget + '-' + sidebarLink.entity"
                :to="sidebarLink.link"
              >
                <i
                  :class="'fa fa-' + sidebarLink.icon"
                  :key="index + 'icon'"
                  style="float: left; font-size: 1rem !important"
                ></i>
                <span class="hide-in-minisidebar"></span>
              </vs-sidebar-item>
              <!-- Dropdown Menu -->
              <template v-else>
                <div
                  :key="index + 'access'"
                  v-if="haveViewAccess(sidebarLink.widget, '')"
                  style="position: relative"
                >
                  <i
                    :class="'fa fa-' + sidebarLink.icon"
                    :key="index + 'icon'"
                    style="
                      float: left;
                      font-size: 1rem !important;
                      padding: 17px 0 15px 15px;
                      color: #586bb2;
                      z-index: 2;
                      position: absolute;
                      top: 0;
                    "
                  ></i>
                  <vs-sidebar-group
                    :title="sidebarLink.name"
                    :key="sidebarLink.index"
                    :open="isSubLinkActive(sidebarLink)"
                    :collapsed="false"
                  >
                    <li
                      v-for="subLink in sidebarLink.childs"
                      :key="subLink.index"
                    >
                      <vs-sidebar-item
                        :icon-pack="subLink.icon"
                        :to="subLink.link"
                        v-if="haveViewAccess(subLink.widget, subLink.entity)"
                        :index="subLink.index"
                        v-on:click="activeItem"
                        :class="'page-' + subLink.widget + '-' + subLink.entity"
                      >
                        <span class="hide-in-minisidebar">{{
                          subLink.name
                        }}</span>
                      </vs-sidebar-item>
                    </li>
                  </vs-sidebar-group>
                </div>
                <!-- /Dropdown Menu -->
              </template>
            </template>
          </template>
        </div>
      </div>

      <div class="footer-sidebar" slot="footer">
        <vs-button
          :style="{ 'margin-right': '0px' }"
          class="w-100 button-logout fw-regular"
          color="#d9004c"
          type="flat"
          @click="logout"
        >
          <i class="mdi mdi-power"></i>
          <span class="hide-in-minisidebar">Log Out</span>
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<style lang="scss">
.footer-sidebar {
  padding-left: 15px;
  padding-right: 15px;
}
.left-sidebar .small-cap {
  padding: 0 !important;
}

.vs-sidebar--group-items {
  margin: 0 0 0 20px !important;
}
</style>

<script>
export default {
  name: "SideBar",
  props: {
    sidebarLinks: {
      type: Array,
      required: true,
    },
    canAccess: {
      type: Function,
    },
    logout: {
      type: Function,
    },
  },
  data: () => ({
    doNotClose: false,
    windowWidth: window.innerWidth,
    round: true,
    specialButton: false,
  }),

  computed: {
    mainMenuLinks() {
      return this.$props.sidebarLinks;
    },

    userName() {
      return this.$store.getters["auth/getUser"];
    },

    //This is for mobile trigger
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", val);
      },
    },

    getActive: function getActive() {
      return this.$destroy();
    },

    //This is for mini sidebar
    reduceSidebar() {
      return Boolean(this.isSidebarReduced);
    },

    isSidebarReduced: {
      get() {
        return this.$store.state.isSidebarReduced;
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_SIDEBAR", val);
      },
    },

    //This is for active group link
    isSubLinkActive() {
      return (sidebarLink) => {
        let open = false;
        if (sidebarLink.childs) {
          sidebarLink.childs.forEach((oneChild) => {
            if (this.$route.fullPath === oneChild.link) {
              open = true;
            }
          });
        }
        return open;
      };
    },
  },
  watch: {
    $route() {
      if (this.windowWidth < 1170)
        this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },
  },
  methods: {
    haveViewAccess(widget, entity) {
      let context = {};
      if (entity.trim() !== "") {
        context.role =
          "ROLE_" + widget.toUpperCase() + "_" + entity.toUpperCase() + "_VIEW";
        return this.$acl.hasAccess(context);
      } else {
        context.role = "ROLE_" + widget.toUpperCase();
        return this.$acl.hasAccess(context);
      }
    },
    activeItem: function () {
      this.$nextTick(function () {
        var currentUrl = window.location.pathname;
        const btns = document.querySelectorAll(".vs-sidebar-item-active");
        btns.forEach((el) => {
          el.classList.remove("vs-sidebar-item-active");
        });
        var array = currentUrl.split("/");
        var item = document.querySelector(
          "div.page-" + array[2] + "-" + array[3]
        );
        item.classList.add("vs-sidebar-item-active");
      });
    },
    findRouteACL(routeEntry) {
      let routeData = this.$router.resolve(routeEntry.url);
      if (
        routeData &&
        routeData.route &&
        routeData.route.name !== "Error 404" &&
        routeData.route.meta &&
        routeData.route.meta.acl
      ) {
        return routeData.route.meta.acl;
      }
      return null;
    },

    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },

    setSidebarWidth() {
      if (this.windowWidth < 1170) {
        this.$store.commit("IS_SIDEBAR_ACTIVE", false);
        if (this.doNotClose === false) {
          this.$store.dispatch("updateSidebarWidth", "no-sidebar");
        }
      } else {
        this.$store.commit("IS_SIDEBAR_ACTIVE", true);
        this.doNotClose = true;

        if (this.isSidebarReduced)
          this.$store.dispatch("updateSidebarWidth", "mini");
        else this.$store.dispatch("updateSidebarWidth", "default");
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.setSidebarWidth();
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"parentx"}},[_c('link',{attrs:{"href":"https://fonts.googleapis.com/icon?family=Material+Icons","rel":"stylesheet"}}),_c('vs-sidebar',{staticClass:"left-sidebar",attrs:{"default-index":"1","hiddenBackground":_vm.doNotClose,"color":"primary","spacer":"","click-not-close":_vm.doNotClose,"reduce":_vm.isSidebarReduced},model:{value:(_vm.isSidebarActive),callback:function ($$v) {_vm.isSidebarActive=$$v},expression:"isSidebarActive"}},[_c('div',{staticClass:"vs-scrollable"},[_c('div',[_vm._l((_vm.mainMenuLinks),function(sidebarLink,index){return [(
              _vm.haveViewAccess(sidebarLink.widget, sidebarLink.entity) &&
              sidebarLink.link
            )?_c('span',{key:index + '.' + index,staticClass:"small-cap"},[_c('i',{key:index + 'icon',class:'fa fa-' + sidebarLink.icon,staticStyle:{"ffloat":"left","font-size":"1rem !important","padding":"17px 0 15px 15px","color":"#586bb2","z-index":"2","position":"absolute","top":"0"}}),_c('vs-sidebar-item',{class:'page-' + sidebarLink.widget + '-' + sidebarLink.entity,attrs:{"to":sidebarLink.link,"index":sidebarLink.index},on:{"click":_vm.activeItem}},[_c('span',{staticClass:"hide-in-minisidebar"},[_vm._v(_vm._s(sidebarLink.name))])])],1):(!sidebarLink.link)?[(
                _vm.haveViewAccess(sidebarLink.widget, sidebarLink.entity) &&
                !sidebarLink.childs
              )?_c('vs-sidebar-item',{key:sidebarLink.index,class:'page-' + sidebarLink.widget + '-' + sidebarLink.entity,attrs:{"index":sidebarLink.index,"to":sidebarLink.link},on:{"click":_vm.activeItem}},[_c('i',{key:index + 'icon',class:'fa fa-' + sidebarLink.icon,staticStyle:{"float":"left","font-size":"1rem !important"}}),_c('span',{staticClass:"hide-in-minisidebar"})]):[(_vm.haveViewAccess(sidebarLink.widget, ''))?_c('div',{key:index + 'access',staticStyle:{"position":"relative"}},[_c('i',{key:index + 'icon',class:'fa fa-' + sidebarLink.icon,staticStyle:{"float":"left","font-size":"1rem !important","padding":"17px 0 15px 15px","color":"#586bb2","z-index":"2","position":"absolute","top":"0"}}),_c('vs-sidebar-group',{key:sidebarLink.index,attrs:{"title":sidebarLink.name,"open":_vm.isSubLinkActive(sidebarLink),"collapsed":false}},_vm._l((sidebarLink.childs),function(subLink){return _c('li',{key:subLink.index},[(_vm.haveViewAccess(subLink.widget, subLink.entity))?_c('vs-sidebar-item',{class:'page-' + subLink.widget + '-' + subLink.entity,attrs:{"icon-pack":subLink.icon,"to":subLink.link,"index":subLink.index},on:{"click":_vm.activeItem}},[_c('span',{staticClass:"hide-in-minisidebar"},[_vm._v(_vm._s(subLink.name))])]):_vm._e()],1)}),0)],1):_vm._e()]]:_vm._e()]})],2)]),_c('div',{staticClass:"footer-sidebar",attrs:{"slot":"footer"},slot:"footer"},[_c('vs-button',{staticClass:"w-100 button-logout fw-regular",style:({ 'margin-right': '0px' }),attrs:{"color":"#d9004c","type":"flat"},on:{"click":_vm.logout}},[_c('i',{staticClass:"mdi mdi-power"}),_c('span',{staticClass:"hide-in-minisidebar"},[_vm._v("Log Out")])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }